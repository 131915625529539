


















































import { defineComponent, ref } from "@vue/composition-api";
import ScrollPanel from "@monorepo/uikit/src/components/tableViews/ScrollPanel.vue";
import TableHeader from "@monorepo/uikit/src/components/tableViews/TableHeader.vue";
import TableSettings from "@monorepo/uikit/src/components/tableViews/TableSettings.vue";
import ExportButton from "@monorepo/uikit/src/components/tableViews/ExportButton.vue";
import InfiniteScroll from "@monorepo/uikit/src/components/tableViews/InfiniteScroll.vue";
import AutoRefreshButton from "@monorepo/uikit/src/components/tableViews/AutoRefreshButton.vue";
import ToggleTableCellsContainer from "@monorepo/informationSecurity/src/views/ExternalAPIUsers/components/users/ToggleTableCellsContainer.vue";
import { mapActions, mapGetters } from "vuex";
import { ModalType } from "@monorepo/utils/src/variables/modalType";
import { tableHeaders } from "@monorepo/utils/src/variables/projectsData/externalApi/tableHeaders";
import eventBus from "@monorepo/utils/src/eventBus";
import useSetStartModal from "@monorepo/utils/src/composables/useSetStartModal";
import Footer from "./FooterUsers.vue";
import SortPanelContainer from "@monorepo/uikit/src/components/common/SortPanelContainer.vue";
import Filters from "./UserFilters.vue";
import Table from "./UserTable.vue";
import SystemAccessesUserInfoModal from "./SystemAccessesUserInfoModal.vue";
import { authorities } from "@monorepo/utils/src/authorities/authorities";
import useSetStartCells from "@monorepo/utils/src/composables/useSetStartCells";
import { isAuthorityExist } from "@monorepo/utils/src/utils/isAuthorityExist";
import useOpenHistoryMode from "@monorepo/utils/src/composables/useOpenHistoryMode";

const SECTION_NAME = "externalAPIUsers";

export default defineComponent({
  name: "Users",
  components: {
    TableSettings,
    ToggleTableCellsContainer,
    AutoRefreshButton,
    ExportButton,
    Table,
    Filters,
    ScrollPanel,
    Footer,
    TableHeader,
    SortPanelContainer,
    InfiniteScroll,
    SystemAccessesUserInfoModal,
  },
  data() {
    return {
      tableHeaders,
    };
  },
  computed: {
    ...mapGetters("auth", ["user"]),
    ...mapGetters(SECTION_NAME, ["users", "isOpenFilters", "totalUsers", "isSelectAll", "infiniteId", "selectedIds", "cells", "isTableLoading"]),
    formattedTableHeaders(): typeof tableHeaders {
      return this.tableHeaders;
    },
    isCanEdit(): boolean {
      return isAuthorityExist(this.user, authorities.USER_MODIFY);
    },
    isShowExport(): boolean {
      return isAuthorityExist(this.user, authorities.USER_EXPORT);
    },
    isShowDeleteBtn(): boolean {
      return isAuthorityExist(this.user, authorities.USER_DELETE);
    },
    isShowFooter(): boolean {
      return (this.isSelectAllElement || !!Object.values(this.selectedIds).filter(Boolean).length) && (this.isShowExport || this.isShowDeleteBtn);
    },
    isSelectAllElement: {
      get(): boolean {
        return this.isSelectAll;
      },
      set(value: boolean) {
        this.$store.dispatch(`${SECTION_NAME}/setIsSelectAll`, value);
      },
    },
  },
  methods: {
    ...mapActions(SECTION_NAME, ["getUserList", "addOpenedUserId", "getExportUsers", "setIsOpenFilters"]),
    openModalIfNeed() {
      if (this.$route.query?.isOpenModal && this.users.length) {
        if (this.$route.query?.id) {
          this.addOpenedUserId(this.$route.query?.id as string);
          this.showModal = true;
          return;
        }
        this.addOpenedUserId(this.users[0].id);
        this.showModal = true;
      } else if (this.$route.query?.card && this.users.length) {
        this.addOpenedUserId(this.$route.query?.card as string);
        this.showModal = true;
      }
    },
    setCells(value: { value: string }[]) {
      this.$store.dispatch(
        `${SECTION_NAME}/setCells`,
        value.map((item) => item.value)
      );
    },
    save() {
      return;
    },
    openModal(isDeleted: boolean) {
      if (isDeleted) {
        return this.toggleModal(true, ModalType.READ);
      }
      this.toggleModal(true, this.isCanEdit ? ModalType.EDIT : ModalType.READ);
    },
    toggleModal(value: boolean, type?: ModalType) {
      this.showModal = value;
      this.type = type || ModalType.NEW;
      if (!value) {
        this.$store.dispatch(`${SECTION_NAME}/addOpenedUserId`, null);
      }
    },
  },
  beforeMount() {
    eventBus.$on("ADD_NEW_USER", this.toggleModal);
    this.$store.commit(`${SECTION_NAME}/refreshUsersData`);
  },
  beforeDestroy() {
    eventBus.$off("ADD_NEW_USER", this.toggleModal);
  },
  setup(props, { root }) {
    const filtersLoaded = ref(true);
    //const isNeedRefresh = ref(false);
    const showModal = ref(false);
    const type = ref(ModalType.EDIT);
    const sectionName = ref(SECTION_NAME); // sectionName === store module name

    const openModal = () => {
      if (root.$store.getters[`${SECTION_NAME}/openedUserId`]) {
        showModal.value = true;
        type.value = root.$store.getters["auth/user"]?.authorities?.find((element: { authority: string }) => {
          return element.authority === authorities.USER_MODIFY;
        })
          ? ModalType.EDIT
          : ModalType.READ;
      }
    };

    useSetStartModal(root, SECTION_NAME, "addOpenedUserId");
    useOpenHistoryMode(root, SECTION_NAME, openModal, "addOpenedUserId");
    // useRefreshTable(root, SECTION_NAME, isNeedRefresh, 15000, "users", "refreshUserList", "openedUserId");
    useSetStartCells(root, SECTION_NAME, root.$store.getters[`${SECTION_NAME}/section`], tableHeaders);

    return {
      filtersLoaded,
      //isNeedRefresh,
      showModal,
      type,
      sectionName,
    };
  },
});
